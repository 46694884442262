import { createStore, applyMiddleware, AnyAction } from "redux"
import thunk, { ThunkMiddleware } from "redux-thunk"
import combineReducers from "./index"
import { SeddiLoginState } from "./store"

export default function configureStore(initialState: any) {
  return createStore(
    combineReducers,
    initialState,
    applyMiddleware(thunk as ThunkMiddleware<SeddiLoginState, AnyAction>)
  )
}