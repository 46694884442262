import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import { Button } from '@seddi/ui-kit';

import { API_KEY, AUTH_DOMAIN, TENANT_ID } from 'src/constants/constants';
import { SignInParameters } from 'src/types/Login';
import { useSubmitOnEnter } from 'src/hooks/useSubmitOnEnter';
import { MixpanelManager } from 'src/tracking/mixpanelManager';
import { SeddiLoginState } from 'src/store/store';

import { GoogleButton } from '../googleButton';
import { Alternative } from '../alternative';
import { NewPasswordField } from '../newPasswordField';
import { NewEmailField } from '../newEmailField';
import { LoginLink } from '../LoginLink';
import * as S from './CreateAccount.styles';

export interface CreateAccountFormProps {
  originalUrl: string;
  signInFunctions: SignInParameters;
  setLoading: Function;
  setSubmited: Function;
  setNewEmail: Function;
  setError: Function;
  newEmail: string;
}

const config = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
};

type SubmitConditions = {
  validEmail: boolean;
  validPassword: boolean;
  noError: boolean;
  acceptedTOS: boolean;
};

export const CreateAccount = ({
  originalUrl,
  signInFunctions,
  setLoading,
  setSubmited,
  setNewEmail,
  setError,
  newEmail,
}: CreateAccountFormProps) => {
  const { name } = useSelector((state: SeddiLoginState) => state.app);
  const [newPassword, setNewPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [canSubmit, setCanSubmit] = useState<SubmitConditions>({
    validEmail: false,
    validPassword: false,
    noError: true,
    acceptedTOS: false,
  });

  const setCanSubmitByField = (key: string, value: boolean) => {
    setCanSubmit({ ...canSubmit, [key]: value });
  };

  const submitEmailAndPassword = async () => {
    try {
      const firebaseApp = initializeApp(config);
      const auth = getAuth(firebaseApp);
      if (TENANT_ID) {
        auth.tenantId = TENANT_ID;
      }

      // Separate try/catch to separate error message types (auth error vs user creation errors)
      try {
        setLoading(true);
        setSubmited(true);
        const userCredential = await createUserWithEmailAndPassword(auth, newEmail, newPassword);
        // Update display name
        await updateProfile(userCredential.user, { displayName: newEmail.split('@')[0] });
        setError(false);
        setLoading(false);
      } catch (error: any) {
        setError(`${error} ${typeof error}`);
        setLoading(false);
        console.log('error: ', error, typeof error);
      }
    } catch (e) {
      setError(true);
      console.log('error: ', e, typeof e);
    } finally {
      if (name === 'author') {
        MixpanelManager.pushTrackEvent('New user', { originalUrl: originalUrl, email: newEmail });
      }
    }
  };

  const submitDisabled = !Object.values(canSubmit).every((c) => !!c);
  useSubmitOnEnter(submitEmailAndPassword, submitDisabled);

  const onEmailFieldFocus = () => {
    if (!showPassword) {
      setShowPassword(true);
    }
  };

  return (
    <S.SectionWrapper>
      <S.InputGroup>
        <GoogleButton signInFunctions={signInFunctions} />
        <Alternative />
        <NewEmailField
          newEmail={newEmail}
          onEmailUpdate={setNewEmail}
          setCanSubmit={setCanSubmitByField}
          onFocus={onEmailFieldFocus}
        />
        <NewPasswordField
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          setCanSubmit={setCanSubmitByField}
          show={showPassword}
        />
      </S.InputGroup>

      <S.CreateAccountSubmitButtonContainer>
        <S.InputGroup>
          <Button
            type='submit'
            text={I18n.t('createAccount.createPrompt')}
            isDisabled={submitDisabled}
            onClick={submitEmailAndPassword}
            data-dd-action-name='createAcount'
            width='full'
            variant='outlined'
          />
          <LoginLink />
        </S.InputGroup>
      </S.CreateAccountSubmitButtonContainer>
    </S.SectionWrapper>
  );
};

export default CreateAccount;
