import React from 'react';

import { I18n } from 'react-redux-i18n';

import { Input } from '@seddi/ui-kit';

import { LoginUtils } from 'src/utils';

import * as S from './NewEmailField.styles';

export const NewEmailField: React.FC<{
  newEmail: string;
  onEmailUpdate: Function;
  setCanSubmit: Function;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}> = ({ newEmail, onEmailUpdate, setCanSubmit, onFocus }) => {
  const handleEmailUpdate = (email: string) => {
    if (newEmail === email) {
      return;
    }

    const correctEmail = LoginUtils.validateEmailFormat(email);
    setCanSubmit('validEmail', correctEmail);
    onEmailUpdate(email);
  };
  return (
    <S.FieldContainer>
      <S.FieldLabel>{I18n.t('createAccount.emailLabel')}</S.FieldLabel>
      <S.InputWrapper>
        <Input
          type='email'
          placeholder={I18n.t('createAccount.emailPrompt')}
          value={newEmail}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEmailUpdate(e.target.value)}
          onFocus={onFocus}
          regex={{
            regex: /\S+@\S+\.\S+/,
            message: I18n.t('createAccount.validEmail'),
          }}
          showTooltip
        />
      </S.InputWrapper>
    </S.FieldContainer>
  );
};
