import { AppName } from 'src/types';

export const APP_VERSION_REQUESTED_VALUE = 'APP_VERSION_REQUESTED';
export type APP_VERSION_REQUESTED_TYPE = typeof APP_VERSION_REQUESTED_VALUE;

export const APP_VERSION_SUCCEEDED_VALUE = 'APP_VERSION_SUCCEEDED';
export type APP_VERSION_SUCCEEDED_TYPE = typeof APP_VERSION_SUCCEEDED_VALUE;

export const APP_VERSION_FAILED_VALUE = 'APP_VERSION_FAILED';
export type APP_VERSION_FAILED_TYPE = typeof APP_VERSION_FAILED_VALUE;

export const APP_CHANGE = 'APP_CHANGE';
export type APP_CHANGE_TYPE = typeof APP_CHANGE;

export interface AppChange {
  type: APP_CHANGE_TYPE;
  name: AppName;
}
export interface AppVersionRequested {
  type: APP_VERSION_REQUESTED_TYPE;
}

export interface AppVersionSucceeded {
  type: APP_VERSION_SUCCEEDED_TYPE;
  isLatestVersion: boolean;
}

export interface AppVersionFailed {
  type: APP_VERSION_FAILED_TYPE;
}

export type AppAction = AppVersionRequested | AppVersionSucceeded | AppVersionFailed | AppChange;
