import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';

import { Button, Input } from '@seddi/ui-kit';

import * as S from './Login.styles';
import { ErrorParameters, LoginMode, SignInParameters } from 'src/types/Login';
import { CommunicationPageTypes } from 'src/features/communicationPage/CommunicationPage.types';
import { SignupLink } from '../signupLink';
import { ResetLink } from '../resetLink';
import { useSubmitOnEnter } from 'src/hooks/useSubmitOnEnter';

interface LoginFormProps {
  error: ErrorParameters;
  updateError: (error: { code?: string; message?: string; retry?: any } | null) => void;
  signInFunctions: SignInParameters;
  mode: LoginMode;
}

export const Login = ({ error, updateError, signInFunctions, mode }: LoginFormProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = () => {
    try {
      signInFunctions?.onSignInWithEmailAndPassword
        ? signInFunctions.onSignInWithEmailAndPassword(email, password)
        : updateError({ message: 'sign-in function missing' });
    } catch (e: any) {
      updateError(e);
    }
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onSubmit();
  };

  useSubmitOnEnter(onSubmit, !email || !password);

  const checkError = () => {
    // display a default error message for any errors
    if (error && error.message && error.message !== '') {
      const errorType =
        error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found'
          ? CommunicationPageTypes.LOGIN
          : CommunicationPageTypes.AUTH;

      return navigate(`/message/${errorType}`);
    }

    // If signed out, display message so the user knows
    if (mode === LoginMode.SignOut) {
      return navigate(`/message/${CommunicationPageTypes.LOGOUT}`);
    }

    // Do not display an error
    return null;
  };

  checkError();
  return (
    <S.Form>
      <S.Field>
        <Input
          autoComplete='username'
          type='email'
          placeholder={I18n.t('login.emailPrompt')}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value);
          }}
        />
        <Input
          autoComplete='current-password'
          type='password'
          placeholder={I18n.t('login.passwordPrompt')}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        />
      </S.Field>
      <S.ActionWrapper>
        <Button
          type='submit'
          text={I18n.t('login.signIn')}
          isDisabled={!email || !password}
          data-dd-action-name='SignIn'
          width='full'
          onClick={onClick}
          variant='outlined'
        />
        <SignupLink />
        <ResetLink />
      </S.ActionWrapper>
    </S.Form>
  );
};

// @ts-ignore
export default Login;
