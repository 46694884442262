import { Dispatch } from 'redux';
import axios from 'axios';
import { AppName } from 'src/types';
import versionJson from '../../version.json';
import * as constants from './appConstants';
import { CustomGlobal } from '../../components/App';

declare const global: CustomGlobal;
global.appVersion = versionJson.version;

export const appVersionRequest = (): constants.AppVersionRequested => {
  return {
    type: constants.APP_VERSION_REQUESTED_VALUE,
  };
};

export const appVersionSuccess = (isLatestVersion: boolean): constants.AppVersionSucceeded => {
  return {
    type: constants.APP_VERSION_SUCCEEDED_VALUE,
    isLatestVersion: isLatestVersion,
  };
};

export const appVersionFailure = (): constants.AppVersionFailed => {
  return {
    type: constants.APP_VERSION_FAILED_VALUE,
  };
};

export const setApp = (name: AppName) => {
  return {
    type: constants.APP_CHANGE,
    name: name,
  };
};

export const getAppVersion = () => {
  return async (dispatch: Dispatch<constants.AppAction>) => {
    dispatch(appVersionRequest());
    const config = { headers: { 'Content-Type': 'application/json', 'Cache-Control': 'no-cache' } };

    try {
      const response = await axios.get(`${window.location.origin}/meta.json`, config);
      const latestVersion = response.data.version;
      const currentVersion = global.appVersion;
      const requiresForcedRefresh = semverGreaterThan(latestVersion, currentVersion);

      dispatch(appVersionSuccess(!requiresForcedRefresh));
    } catch (error) {
      dispatch(appVersionFailure());
    }
  };
};

const semverGreaterThan = (latest: string, current: string) => {
  const versionsA = latest.split(/\./g);
  const versionsB = current.split(/\./g);

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};
