import React from 'react';
import { Tooltip } from '@seddi/ui-kit';

import * as S from './ErrorTooltip.styles';

export const ErrorTooltip: React.FC<{ error?: string; children: React.ReactNode }> = ({
  error,
  children,
}) => {
  return error ? (
    <Tooltip position='bottom' type='text' content={<S.InfoTooltip>{error}</S.InfoTooltip>}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
};
