import { AppName } from 'src/types';
import configureStore from './configureStore';

export interface SeddiLoginState {
  app: {
    isLoading: boolean;
    isLatestVersion: boolean;
    name: AppName;
    originalUrl?: string;
  };
  login: {
    loginPageUrl: string;
  };
  isFlagsLoaded: boolean;
}

export const initialState: SeddiLoginState = {
  app: {
    isLoading: true,
    isLatestVersion: true,
    name: 'seddi',
  },
  login: {
    loginPageUrl: '',
  },
  isFlagsLoaded: false,
};

export const store = configureStore(initialState);
