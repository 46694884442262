import styled from 'styled-components';

export const Accordion = styled.div<{ show: boolean }>`
  display: grid;
  grid-template-rows: ${({ show }) => (show ? 1 : 0)}fr;
  transition: grid-template-rows 0.25s ease;
`;

export const AccordionContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FieldContainer = styled.div``;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FieldLabel = styled.label`
  ${({ theme: { font } }) => font.t3};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InfoIcon = styled.div`
  margin-left: 4px;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InfoTooltip = styled.div`
  width: 196px;
  white-space: normal;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CheckboxLabel = styled.div`
  margin-left: 10px;
  ${({ theme }) => theme.font.t2}
`;

export const HyperLink = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.font.t3}
  color: ${({ theme }) => theme.color.s1}
`;

export const InputIconWrapperContainer = styled.div`
  position: absolute;
  right: 6px;
  top: 3px;
  display: flex;
  width: 16px;
  height: 32px;
`;

export const InputIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

export const FieldInputWrapper = styled.div`
  position: relative;
`;

export const InputIconWrapper = styled.div`
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`;

export const ErrorMessage = styled.div`
  ${({ theme: { font } }) => font.t3};
  color: ${({ theme: { color } }) => color.ac11};
  margin: 1.25rem 0 0.5rem;
  text-align: center;
`;
