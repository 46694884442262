import React from 'react';
import { useParams } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import { FormContainer } from '../../components/formContainer';
import { CommunicationPageTypes } from './CommunicationPage.types';
import { REDIRECT_URI } from '../../constants/constants';

import { StyledLink } from './CommunicationPage.styles';

export const CommunicationPage: React.FC = () => {
  const { type } = useParams();

  let [title, error, actionText, actionUrl]: (string | undefined)[] = [];

  switch (type) {
    case CommunicationPageTypes.SIGNUP: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.singUpError');
      actionText = I18n.t('error.backSignup');
      actionUrl = '/create-account';
      break;
    }
    case CommunicationPageTypes.ACCOUNT_EXISTS: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.accountExist');
      actionText = I18n.t('error.backSignup');
      actionUrl = '/create-account';
      break;
    }
    case CommunicationPageTypes.LOGIN: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.logInError');
      actionText = I18n.t('error.backLogin');
      actionUrl = REDIRECT_URI;
      break;
    }
    case CommunicationPageTypes.AUTH: {
      title = I18n.t('error.wrong');
      error = I18n.t('error.auth');
      actionText = I18n.t('error.backLogin');
      actionUrl = REDIRECT_URI;
      break;
    }
    case CommunicationPageTypes.LOGOUT: {
      title = I18n.t('error.loggedOut');
      actionText = I18n.t('error.backLogout');
      actionUrl = REDIRECT_URI;
      break;
    }
    default:
      break;
  }

  return (
    <FormContainer title={title} text={error}>
      <StyledLink to={actionUrl}>{actionText}</StyledLink>
    </FormContainer>
  );
};
