import { AppName } from 'src/types';
import * as constants from './appConstants';

export type AppStoreState = {
  isLoading: boolean;
  isLatestVersion: boolean;
  name: AppName;
  originalUrl?: string;
};

const appReducer = (
  state = { isLoading: true, isLatestVersion: true, name: 'author' } as AppStoreState,
  action: constants.AppAction,
) => {
  let newState = state;

  switch (action.type) {
    case constants.APP_VERSION_SUCCEEDED_VALUE:
      newState = {
        ...newState,
        isLoading: false,
        isLatestVersion: action.isLatestVersion,
      };
      break;
    case constants.APP_VERSION_FAILED_VALUE:
      newState = {
        ...newState,
        isLoading: false,
        isLatestVersion: true, // assume true for now so we don't continue to try and fetch the version
      };
      break;
    case constants.APP_CHANGE:
      newState = {
        ...newState,
        name: action.name,
      };
      break;
    default:
      newState = { ...newState };
      break;
  }
  return newState;
};

export default appReducer;
