export const SET_LOGIN_PAGE_URL_VALUE = "SET_LOGIN_PAGE_URL"
export type SET_LOGIN_PAGE_URL_TYPE = typeof SET_LOGIN_PAGE_URL_VALUE

export interface SetLoginPageUrl {
  type: SET_LOGIN_PAGE_URL_TYPE
  loginPageUrl: string
}

export type LoginAction =
  SetLoginPageUrl
