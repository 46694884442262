import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { BrandPageBackground, Loading, LoadingBackground } from '@seddi/ui-kit';

import { APP_LOOKUP } from 'src/constants';
import { SeddiLoginState } from 'src/store/store';
import { ProgressType } from 'src/types/Login';

export const ProgressBar = ({ progressType }: { progressType: string }) => {
  const { name, originalUrl } = useSelector((state: SeddiLoginState) => state.app);

  let mode;
  switch (progressType) {
    case ProgressType.SignIn:
      mode = 'signingIn';
      break;
    case ProgressType.SignOut:
      mode = 'signingOut';
      break;
    case ProgressType.None:
    default:
      mode = 'loading';
  }

  const backgroundImage = useMemo(
    () => (originalUrl ? `${window.location.origin}/img/${APP_LOOKUP[name].backgroundImage}` : ''),
    [name, originalUrl],
  );
  const backgroundGradient = useMemo(() => APP_LOOKUP[name].gradient, [name]);

  const isLoading = mode === 'loading';

  return (
    <BrandPageBackground background={backgroundGradient} image={backgroundImage}>
      <LoadingBackground>
        <Loading
          isCustomMessage={!isLoading}
          message={I18n.t(`login.${mode}`)}
          name={name.charAt(0).toUpperCase() + name.slice(1)}
          percentage={isLoading ? 5 : 50}
        />
      </LoadingBackground>
    </BrandPageBackground>
  );
};
