import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';

import { Button, ThemeProvider } from '@seddi/ui-kit';

import { SeddiTheme } from 'src/theme/SeddiTheme';
import { FormContainer } from '../formContainer';
import { ThemeApp } from '../../theme/theme';

interface RecoverEmailProps {
  continueUrl: string;
}

const RecoverEmail = (props: RecoverEmailProps) => {
  const navigate = useNavigate();

  const { continueUrl } = props;
  // seddi.me is part of the Fitroom domain
  const brandName = continueUrl && continueUrl.includes('seddi.me') ? 'fitroom' : 'seddi';

  // TODO: not sure why we need the ThemeProvider here to get the correct colors for fitroom, when the other components on the same level do not.
  let theme = structuredClone(SeddiTheme);
  if (brandName === 'fitroom') {
    theme.color.s1 = ThemeApp.color.sf1;
    theme.color.s2 = ThemeApp.color.sf2;
  }

  return (
    <ThemeProvider theme={theme}>
      <FormContainer brandName={brandName} panelPosition='right' mode={'sidePanel'}>
        <Button
          width='full'
          data-dd-action-name='returnToLogin'
          text={I18n.t('reset.returnToLogin')}
          onClick={() => navigate('/login')}
        />
      </FormContainer>
    </ThemeProvider>
  );
};

export default RecoverEmail;
