import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import { datadogRum } from '@datadog/browser-rum';

import { ENV } from './constants/constants';
import App from './components/App';
import { store } from './store/store';
import translationsObject from './locales';
import './stylesheets/main.scss';
import version from './version.json';
import { ThemeWrapper } from './theme/ThemeWrapper';
import { MixpanelManager } from './tracking/mixpanelManager';

datadogRum.init({
  applicationId: '01891e63-9bf1-43d8-a9bf-f7525e5f7c83',
  clientToken: 'pube63e8742de070af14e172c3f1f4e3b8b',
  site: 'datadoghq.com',
  service: 'seddi-login',
  env: ENV,
  version: version.version,
  sessionSampleRate: 100,
  trackUserInteractions: true,
});
datadogRum.startSessionReplayRecording();

MixpanelManager.init();

function handleFirstTab(e: any) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');

    window.removeEventListener('keydown', handleFirstTab);
    window.addEventListener('mousedown', handleMouseDownOnce);
  }
}

function handleMouseDownOnce() {
  document.body.classList.remove('user-is-tabbing');

  window.removeEventListener('mousedown', handleMouseDownOnce);
  window.addEventListener('keydown', handleFirstTab);
}

window.addEventListener('keydown', handleFirstTab);

function getLocale(): string {
  const availableLocales = Object.keys(translationsObject);
  const defaultLocale = 'en';

  // Locale Autodetection, defaulting to "en" if nothing is found
  const storedLocale = localStorage.getItem('SGCS::LOCALE');
  const browserLocale = (
    (window.navigator.languages ? window.navigator.languages[0] : null) ||
    window.navigator.language ||
    defaultLocale
  )
    .substr(0, 2)
    .toLowerCase();

  let determinedLocale = defaultLocale;
  if (storedLocale && availableLocales.includes(storedLocale)) {
    determinedLocale = storedLocale;
  } else if (availableLocales.includes(browserLocale)) {
    determinedLocale = browserLocale;
  }
  return determinedLocale;
}

window.addEventListener('keydown', handleFirstTab);

const locale = getLocale();
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale(locale));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeWrapper>
        <App />
      </ThemeWrapper>
    </BrowserRouter>
  </Provider>,
);
