import styled from 'styled-components';

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Message = styled.div<{ $isError?: boolean }>`
  margin: 1.25rem 0 0.5rem;
  text-align: center;
  ${({ theme: { font } }) => font.t3};
  color: ${({ theme: { color }, $isError }) => ($isError ? color.ac11 : color.ui2)};
`;
