import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setApp } from 'src/store/app/appActions';

import ChangePassword from './changePassword/ChangePassword';
import RecoverEmail from '../../components/auth/RecoverEmail';
import Verification from '../../components/auth/Verification';
import SignIn from '../../components/auth/SignIn';

enum PageStates {
  ChangePassword = 'resetPassword',
  RecoverEmail = 'recoverEmail',
  VerifyEmail = 'verifyEmail',
  SignIn = 'signIn',
}

const AuthCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const [continueUrl, setContinueUrl] = useState<string>('');
  const [pageMode, setPageMode] = useState<PageStates>();
  const [email, setEmail] = useState<string>('');

  const dispatch = useDispatch();

  // TODO(omer): We will not be setting app here anymore! Remove this ASAP. It will be handled at an upper level. We will just use the 'name' from app selector.
  useEffect(() => {
    if (!searchParams.has('mode') || !searchParams.has('oobCode')) navigate('/');

    const modeParameter = searchParams.get('mode') || '';
    const codeParameter = searchParams.get('oobCode') || '';
    const continueUrlParameter = searchParams.get('continueUrl') || '';
    const emailParameter = searchParams.get('email') || '';

    setEmail(emailParameter);
    setCode(codeParameter);
    setContinueUrl(continueUrlParameter);

    // TODO(omer): Also add Textura here, also maybe use a switch case instead of else if
    // check continue url to show a different background based on which app we are expecting
    if (continueUrlParameter?.includes('fitroom')) {
      dispatch(setApp('fitroom'));
    } else if (continueUrlParameter?.includes('seddi.dev')) {
      dispatch(setApp('author'));
    } else {
      // Default to Author
      dispatch(setApp('author'));
    }

    switch (modeParameter) {
      case PageStates.RecoverEmail:
        setPageMode(PageStates.RecoverEmail);
        break;
      case PageStates.VerifyEmail:
        setPageMode(PageStates.VerifyEmail);
        break;
      case PageStates.ChangePassword:
        setPageMode(PageStates.ChangePassword);
        break;
      case PageStates.SignIn:
        setPageMode(PageStates.SignIn);
        break;
      default:
      // navigate('/');
    }

    // We do not care about dependencies because this is only called on mount, but there is an issue with linter so we comment it out
    // see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook for more info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, navigate]);

  return (
    <>
      {pageMode === PageStates.ChangePassword && (
        <ChangePassword code={code} continueUrl={continueUrl} />
      )}
      {pageMode === PageStates.VerifyEmail && (
        <Verification code={code} continueUrl={continueUrl} />
      )}
      {pageMode === PageStates.RecoverEmail && (
        <RecoverEmail continueUrl={continueUrl} />
      )}
      {pageMode === PageStates.SignIn && (
        <SignIn email={email} continueUrl={continueUrl} />
      )}
    </>
  );
};

export default AuthCallback;
