import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { Checkbox, Input, Tooltip } from '@seddi/ui-kit';

import { LoginUtils } from 'src/utils';

import * as S from './NewPasswordField.styles';
import { ErrorTooltip } from '../errorTooltip';

export const NewPasswordField: React.FC<{
  setNewPassword: Function;
  setCanSubmit: Function;
  newPassword: string;
  show: boolean;
  showAgree?: boolean;
}> = ({ setNewPassword, setCanSubmit, newPassword, show = true, showAgree = true }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordControls, setPasswordControls] = useState<any>({});

  const [rePassword, setRePassword] = useState<string>('');
  const [passwordMatched, setPasswordMatched] = useState<boolean>(false);
  const [acceptedTOS, setAcceptedTOS] = useState<boolean>(false);

  useEffect(() => {
    // Compare rePassword and newPassword state in every update
    const areEqual = !!rePassword?.length && !!newPassword?.length && rePassword === newPassword;
    setPasswordMatched(areEqual);
    setCanSubmit('validPassword', passwordControls?.strong && areEqual);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rePassword, newPassword, passwordControls]);

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setNewPassword(password);
    const params = LoginUtils.checkPasswordStrength(password);
    if (params.passwordControls) {
      setPasswordControls(params.passwordControls);
    }
    setCanSubmit('validPassword', params.passwordControls?.strong && passwordMatched);

    if (params.newPassword) {
      setNewPassword(params.newPassword);
    }
  };

  const onTosClick = () => {
    const newTOS = !acceptedTOS;
    setCanSubmit('acceptedTOS', newTOS);
    setAcceptedTOS(newTOS);
  };

  return (
    <S.Accordion show={show}>
      <S.AccordionContent>
        <S.SectionContainer>
          <S.FieldContainer>
            <S.FieldLabel>
              {I18n.t('createAccount.enterPasswordPrompt')}
              <Tooltip
                position='bottom'
                type='text'
                content={<S.InfoTooltip>{I18n.t('createAccount.enterPasswordInfo')}</S.InfoTooltip>}
              >
                <S.InfoIcon>
                  <img alt='Seddi check' src={`/img/info.svg`} />
                </S.InfoIcon>
              </Tooltip>
            </S.FieldLabel>
            <S.FieldInputWrapper>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={handlePasswordChange}
                regex={{
                  externalValidation: { valid: passwordControls?.strong },
                  message: I18n.t('createAccount.errorPasswordStrength'),
                }}
                showTooltip
              />
              <S.InputIconWrapperContainer>
                {passwordMatched && passwordControls.strong ? (
                  <S.InputIconWrapper>
                    <S.InputIcon alt='Seddi check' src={`/img/check.svg`} />
                  </S.InputIconWrapper>
                ) : (
                  <S.InputIconWrapper onClick={() => setShowPassword(!showPassword)}>
                    <S.InputIcon
                      alt='Seddi Eye'
                      src={showPassword ? `/img/open_eye.svg` : `/img/eye.svg`}
                    />
                  </S.InputIconWrapper>
                )}
              </S.InputIconWrapperContainer>
            </S.FieldInputWrapper>
          </S.FieldContainer>
          <S.FieldContainer>
            <S.FieldLabel>{I18n.t('createAccount.reEnterPasswordPrompt')}</S.FieldLabel>
            <S.FieldInputWrapper>
              <ErrorTooltip error={undefined}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  value={rePassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const password = e.target.value;
                    setRePassword(password);
                  }}
                  regex={{
                    externalValidation: { valid: passwordMatched },
                    message: I18n.t('createAccount.errorPasswordMatch'),
                  }}
                  showTooltip
                />
              </ErrorTooltip>
              <S.InputIconWrapperContainer>
                {passwordMatched && passwordControls.strong ? (
                  <S.InputIconWrapper>
                    <S.InputIcon alt='Seddi check' src={`/img/check.svg`} />
                  </S.InputIconWrapper>
                ) : (
                  <S.InputIconWrapper onClick={() => setShowPassword(!showPassword)}>
                    <S.InputIcon
                      alt='Seddi Eye'
                      src={showPassword ? `/img/open_eye.svg` : `/img/eye.svg`}
                    />
                  </S.InputIconWrapper>
                )}
              </S.InputIconWrapperContainer>
            </S.FieldInputWrapper>
          </S.FieldContainer>
        </S.SectionContainer>
        <S.FieldContainer>
          {showAgree ? (
            <S.CheckboxWrapper>
              <Checkbox label='' checked={acceptedTOS} onClick={onTosClick} />
              <S.CheckboxLabel>
                {I18n.t('createAccount.agreeTos')}
                <S.HyperLink href='https://seddi.com/terms/' target='_blank'>
                  {I18n.t('createAccount.termsOfService')}
                </S.HyperLink>
                {I18n.t('createAccount.and')}
                <S.HyperLink href='https://seddi.com/privacy/' target='_blank'>
                  {I18n.t('createAccount.privacyPolicy')}
                </S.HyperLink>
              </S.CheckboxLabel>
            </S.CheckboxWrapper>
          ) : null}
        </S.FieldContainer>
      </S.AccordionContent>
    </S.Accordion>
  );
};
