import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export const DomainUrlRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate({
      pathname: '/message/auth-error',
      search: location.search,
    });
    // We do not care about dependencies because this is only called on mount, but there is an issue with linter so we comment it out
    // see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook for more info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <span data-test-component='DomainUrlRedirect'> </span>;
};
