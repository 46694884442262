import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router';

import { flagsLoadThunk } from '@seddi/ui-feature-flags';

import { Authentication } from '../features/authentication';
import versionJson from '../version.json';
import { AppAction } from '../store/app/appConstants';
import { SeddiLoginState } from '../store/store';
import { getAppVersion } from '../store/app/appActions';
import AuthTemplate from '../features/authCallback/AuthCallback';
import { useDispatch } from 'react-redux';
import { AUTH_DOMAIN, ENV, GITLAB_UNLEASH_PROXY_BASE_URL } from '../constants/constants';
import { Navigate } from 'react-router-dom';
import { CommunicationPage } from 'src/features/communicationPage';
interface AppProps {
  isLoading: boolean;
  isLatestVersion: boolean;
  getAppVersion: () => AppAction;
}
export interface CustomGlobal {
  appVersion: string;
}

declare const global: CustomGlobal;
global.appVersion = versionJson.version;

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(async function (names) {
      await Promise.all(names.map((name) => caches.delete(name)));
    });
  }
  // delete browser cache and hard reload
  window.location.reload();
};

const App = ({ isLoading, isLatestVersion, getAppVersion }: AppProps) => {
  const dispatch = useDispatch<any>();

  useEffect(() => {
    getAppVersion();

    // if not using local environment, and localhost is not in the auth domain, then we should try to connect to the proxy
    if (ENV !== 'LOCAL' && !AUTH_DOMAIN?.includes('localhost')) {
      const unleashConfig = {
        url: GITLAB_UNLEASH_PROXY_BASE_URL + '/proxy',
        clientKey: 'login',
        refreshInterval: 600,
        appName: 'login',
      };
      dispatch(flagsLoadThunk({ type: 'UNLEASH', config: unleashConfig }));
    } else {
      // eslint-disable-next-line camelcase
      const flags = {};
      dispatch(flagsLoadThunk({ type: 'LOCAL', config: { flags } }));
    }

    // We do not care about dependencies because this is only called on mount, but there is an issue with linter so we comment it out
    // see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook for more info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }

  if (!isLoading && !isLatestVersion) {
    //reload
    refreshCacheAndReload();
  }

  return (
    <div id='login-background' style={{ height: '100vh', width: '100vw', overflow: 'hidden' }}>
      <main role='main'>
        <Routes>
          <Route path='auth-callback' element={<AuthTemplate />} />
          <Route path='message/:type' element={<CommunicationPage />} />
          <Route path='/' element={<Authentication />}>
            <Route path='login' element={<Authentication />} />
            <Route path='reset-password' element={<Authentication />} />
            <Route path='create-account' element={<Authentication />} />
          </Route>
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </main>
    </div>
  );
};

export const mapStateToProps = (state: SeddiLoginState) => {
  return {
    isLoading: state.app.isLoading,
    isLatestVersion: state.app.isLatestVersion,
  };
};

export const mapDispatchToProps = (dispatch: any) => {
  return {
    getAppVersion: () => dispatch(getAppVersion()),
  };
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(App);
