import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { initializeApp } from 'firebase/app';
import { getAuth, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';

import { Button, ThemeProvider, Link } from '@seddi/ui-kit';

import { API_KEY, AUTH_DOMAIN, REDIRECT_URI, TEXTURA_URI, TENANT_ID } from '../../../constants/constants';
import * as S from './ChangePassword.styles';
import { FormContainer } from '../../../components/formContainer';
import { NewPasswordField } from '../../../components/newPasswordField';
import { SeddiTheme } from 'src/theme/SeddiTheme';
import { ThemeApp } from '../../../theme/theme';
import { useSubmitOnEnter } from 'src/hooks/useSubmitOnEnter';

interface IAskResetEmail {
  code: string;
  continueUrl: string;
}

interface ISuccessMessageField {
  continueUrl: string;
}

const config = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
};

type SubmitConditions = {
  validPassword: boolean;
  noError: boolean;
};

const SuccessMessageField: React.FC<ISuccessMessageField> = (props: ISuccessMessageField) => {
  const { continueUrl } = props;

  const authorTheme = structuredClone(SeddiTheme);
  authorTheme.color.s1 = ThemeApp.color.sa1;
  authorTheme.color.s2 = ThemeApp.color.sa2;

  const texturaTheme = structuredClone(SeddiTheme);
  texturaTheme.color.s1 = ThemeApp.color.st1;
  texturaTheme.color.s2 = ThemeApp.color.st2;

  const fitroomTheme = structuredClone(SeddiTheme);
  fitroomTheme.color.s1 = ThemeApp.color.sf1;
  fitroomTheme.color.s2 = ThemeApp.color.sf2;

  // seddi.me is part of the Fitroom domain
  const app = continueUrl && continueUrl.includes('seddi.me') ? 'fitroom' : 'seddi';

  const loginTo = (name: string) => {
    if (name === 'textura') { 
      window.location.href = TEXTURA_URI;
    } else {
      window.location.href = REDIRECT_URI; 
    }
  };

  if (app === 'fitroom') {
    return (
      <S.MessageFieldContainer>
        <ThemeProvider theme={fitroomTheme}>
          <Button
            text={I18n.t('auth.loginFitroom')}
            data-dd-action-name='SignIn'
            type='button'
            width='full'
            onClick={() => loginTo('fitroom')}
          />
        </ThemeProvider>
      </S.MessageFieldContainer>
    );
  }

  return (
    <S.MessageFieldContainer>
      <ThemeProvider theme={authorTheme}>
        <Button
          text={I18n.t('auth.loginAuthor')}
          data-dd-action-name='SignIn'
          type='button'
          width='full'
          onClick={() => loginTo('author')}
        />
      </ThemeProvider>
      <ThemeProvider theme={texturaTheme}>
        <Button
          text={I18n.t('auth.loginTextura')}
          data-dd-action-name='SignIn'
          type='button'
          width='full'
          onClick={() => loginTo('textura')}
        />
      </ThemeProvider>
    </S.MessageFieldContainer>
  );
};

const ChangePassword = (props: IAskResetEmail) => {
  const { code, continueUrl } = props;
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordChangedSuccessful, setPasswordChangedSuccessful] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [transitionEffect, setTransitionEffect] = useState(false);

  const [canSubmit, setCanSubmit] = useState<SubmitConditions>({
    validPassword: true,
    noError: true,
  });

  const setCanSubmitByField = (key: string, value: boolean) => {
    setCanSubmit({ ...canSubmit, [key]: value });
  };

  const submitPassword = () => {
    try {
      setLoading(true);
      const firebaseApp = initializeApp(config);
      const auth = getAuth(firebaseApp);
      if (TENANT_ID) {
        auth.tenantId = TENANT_ID;
      }
      confirmPasswordReset(auth, code, newPassword)
        .then(() => {
          // Password reset auth sent.
          setLoading(false);
          setPasswordChangedSuccessful(true);
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error.message);
        });
    } catch (e) {
      setLoading(false);
      setErrorMessage(I18n.t('login.signInError'));
      console.log('error: ', e, typeof e);
    }
  };

  const submitDisabled = !Object.values(canSubmit).every((c) => !!c);
  useSubmitOnEnter(submitPassword, submitDisabled);

  useEffect(() => {
    if (passwordChangedSuccessful)
      setTimeout(() => {
        setTransitionEffect(true);
      }, 200);
  }, [passwordChangedSuccessful]);

  useEffect(() => {
    const verifyCode = (_code: string) => {
      try {
        const firebaseApp = initializeApp(config);
        const auth = getAuth(firebaseApp);
        if (TENANT_ID) {
          auth.tenantId = TENANT_ID;
        }
        verifyPasswordResetCode(auth, code)
          .then(() => {})
          .catch((error) => {
            console.log(error.message);
            setErrorMessage(error.message);
          });
      } catch (e) {
        setErrorMessage(I18n.t('login.signInError'));
        console.log('error: ', e, typeof e);
      }
    };

    verifyCode(code);
  }, [code]);

  // seddi.me is part of the Fitroom domain
  const brandName = continueUrl && continueUrl.includes('seddi.me') ? 'fitroom' : 'seddi';

  // TODO: not sure why we need the ThemeProvider here to get the correct colors for fitroom, when the other components on the same level do not.
  let theme = structuredClone(SeddiTheme);
  if (brandName === 'fitroom') {
    theme.color.s1 = ThemeApp.color.sf1;
    theme.color.s2 = ThemeApp.color.sf2;
  }

  // Render different information based on error type
  if (errorMessage !== '') {
    // Invalid code
    if (
      errorMessage?.includes('expired-action-code') ||
      errorMessage?.includes('invalid-action-code')
    ) {
      return (
        <ThemeProvider theme={theme}>
          <FormContainer
            brandName={brandName}
            panelPosition='right'
            mode={'sidePanel'}
            title={I18n.t('error.wrong')}
            text={I18n.t('auth.invalidCodeMessage')}
          >
            <S.LinkButtonContainer>
              <Button
                text={I18n.t('reset.resendPrompt')}
                data-dd-action-name='resendPasswordResetInstructions'
                type='button'
                onClick={() => navigate('/reset-password')}
                width='full'
              />
              <S.LoginLinkContainer>
                <Link
                  data-dd-action-name='returnToLogin'
                  onClick={() => navigate('/login')}
                  text={I18n.t('reset.returnToLogin')}
                />
              </S.LoginLinkContainer>
            </S.LinkButtonContainer>
          </FormContainer>
        </ThemeProvider>
      );
    }

    // // User not found
    if (errorMessage?.includes('user-disabled') || errorMessage?.includes('user-not-found')) {
      return (
        <ThemeProvider theme={theme}>
          <FormContainer
            brandName={brandName}
            panelPosition='right'
            mode={'sidePanel'}
            title={I18n.t('error.wrong')}
            text={I18n.t('auth.userNotFoundMessage')}
          >
            <Button
              text={I18n.t('reset.returnToLogin')}
              data-dd-action-name='returnToLogin'
              type='button'
              onClick={() => navigate('/login')}
              width='full'
            />
          </FormContainer>
        </ThemeProvider>
      );
    }
  }

  // Display the reset password flow
  return (
    <ThemeProvider theme={theme}>
      <FormContainer
        brandName={brandName}
        panelPosition='right'
        mode={passwordChangedSuccessful || loading ? 'centered' : 'sidePanel'}
        loading={loading}
      >
        <S.Accordion show={!passwordChangedSuccessful || transitionEffect}>
          <S.AccordionContent>
            <S.MainContainer>
              <S.Heading $isSuccess={passwordChangedSuccessful}>
                {passwordChangedSuccessful
                  ? I18n.t('auth.passwordChanged')
                  : I18n.t('auth.changePasswordPrompt')}
                <br />
              </S.Heading>
              {passwordChangedSuccessful ? (
                <SuccessMessageField continueUrl={continueUrl} />
              ) : (
                <S.PasswordWrapper>
                  <NewPasswordField
                    setNewPassword={setNewPassword}
                    setCanSubmit={setCanSubmitByField}
                    newPassword={newPassword}
                    show={true}
                    showAgree={false}
                  />
                  <Button
                    text={I18n.t('auth.changePassword')}
                    data-dd-action-name='SignIn'
                    type='submit'
                    onClick={submitPassword}
                    width='full'
                    isDisabled={submitDisabled}
                  />
                </S.PasswordWrapper>
              )}
            </S.MainContainer>
          </S.AccordionContent>
        </S.Accordion>
      </FormContainer>
    </ThemeProvider>
  );
};

export default ChangePassword;
