import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useLocation, useNavigate } from 'react-router-dom';

import { Link } from '@seddi/ui-kit';

import * as S from './LoginLink.styles';

export const LoginLink: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate({
      pathname: '/login',
      search: location.search,
    });
  };
  return (
    <S.LinkWrapper>
      <Link data-dd-action-name='loginLink' text={I18n.t('login.loginLink')} onClick={onClick} />
    </S.LinkWrapper>
  );
};
