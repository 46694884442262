import styled from 'styled-components';

export const HelpText = styled.div`
  ${({ theme: { font } }) => font.t4};
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LoginFooterWrapper = styled.div`
  color: ${({ theme: { font } }) => font.t3};
  font-size: 0.75rem;

  a {
    color: ${({ theme: { color } }) => color.s1};
    text-decoration: none;
  }
`;
