/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';

export const useSubmitOnEnter = (onSubmit: Function, cantSubmit: boolean) => {
    const onEnter = (e: KeyboardEvent) => {
        if (cantSubmit) return
        if (e.key === 'Enter') onSubmit();
    };

    useEffect(() => {
        window.addEventListener('keydown', onEnter);
        return () => {
            window.removeEventListener('keydown', onEnter);
        };
    }, [onEnter]);

    return;
}