import styled from 'styled-components';

export const GoogleButtonContainer = styled.button`
  width: 100%;
  border: none;
  background: ${({ theme: { color } }) => color.ui6};
  box-shadow: ${({ theme: { shadows } }) => shadows.outerShadow1};
  border-radius: 16px;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoogleButtonImage = styled.img`
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`;

export const GoogleButtonText = styled.div`
  ${({ theme: { font } }) => font.t3};
  color: ${({ theme: { color } }) => color.s1};
`;
