import { combineReducers } from 'redux';
import appReducer from './app/appReducer';
import { i18nReducer } from 'react-redux-i18n';
import loginReducer from './login/loginReducer';
import { flagsLoadReducer } from '@seddi/ui-feature-flags';

export default combineReducers({
  app: appReducer,
  login: loginReducer,
  isFlagsLoaded: flagsLoadReducer,
  i18n: i18nReducer,
});
