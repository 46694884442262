export enum LoginMode {
    SignIn = 'SIGN_IN',
    SignInWithEmail = 'SIGN_IN_WITH_EMAIL',
    SignOut = 'SIGN_OUT',
    ProgressBar = 'PROGRESS_BAR',
    None = 'NONE',
}

export enum ProgressType {
    SignIn = 'SIGN_IN',
    SignOut = 'SIGN_OUT',
    None = 'NONE',
}

export interface SignInParameters {
    onSignInWithGoogle: () => boolean;
    onSignInWithEmailAndPassword: (email: string, password: string) => Promise<boolean>;
}

export interface ErrorParameters {
    message?: string;
    code?: string;
    retry?: () => void;
}