import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useLocation, useNavigate } from 'react-router-dom';

import { Link } from '@seddi/ui-kit';

import * as S from './SignupLink.styles';

export const SignupLink: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick = () => {
    navigate({
      pathname: '/create-account',
      search: location.search,
    });
  };
  return (
    <S.LinkWrapper>
      <Link
        data-dd-action-name='createAccount'
        text={I18n.t('createAccount.createAccountLink')}
        onClick={onClick}
      />
    </S.LinkWrapper>
  );
};
