import { I18n } from "react-redux-i18n";

import * as owasp from "owasp-password-strength-test"

type checkPpasswordParams = {
  strength: number,
  passwordStrengthMessage?: string,
  passwordControls?: owasp.TestResult,
  newPassword?: string,
}

// Pass a hash of settings to the `config` method. The settings shown here are
// the defaults.
owasp.config({
  allowPassphrases: true,
  maxLength: 128,
  minLength: 12,
  minPhraseLength: 12,
  minOptionalTestsToPass: 4
});

export class LoginUtils {
  // checks password strength
  static checkPasswordStrength = (password: string): checkPpasswordParams => {
    let strength = 0;
    let passwordStrengthMessage;
    let passwordControls;
    let newPassword;

    // if password is empty then set default message
    if (password === "") {
      passwordStrengthMessage = I18n.t("auth.inputFieldMessages.createStrongPassword");
      return {
        strength,
        passwordStrengthMessage,
      }
    }

    // replace several spaces
    const newTestPassword = password.replace(/\s+/g, ' ');
    if (newTestPassword !== password) newPassword = newTestPassword;

    // Password strong meter has 8 small box in UI
    const passwordMeterBoxCount = 8;

    // test password strength
    const control = owasp.test(password);
    passwordControls = control;

    // Password is strong
    if (control.strong) {
      // if password is strong then fill 8 box
      strength = passwordMeterBoxCount;
    } else if (control.errors.length === 4) {
      // there is 5 controls in owasp, if passed only 1 control than fill 1 box
      strength = 1;
    } else {
      // there is 5 control in owasp and 8 box in UI, multiply error length with 2 for find how many box to fill
      strength = passwordMeterBoxCount - (control.errors.length * 2);
    }

    if (control.strong) {
      passwordStrengthMessage = I18n.t("auth.inputFieldMessages.strongPassword");
    } else if (control.errors.length) {
      // Set first message in error array as message
      passwordStrengthMessage = control.errors[0];
    }

    return {
      strength,
      passwordStrengthMessage,
      passwordControls,
      newPassword,
    }
  };

  // Checks auth format if typed correct format
  static validateEmailFormat = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
}