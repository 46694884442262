import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router';
import { useNavigate } from 'react-router-dom';

import * as ciap from 'gcip-iap';

import { setApp } from 'src/store/app/appActions';
import { setLoginPageUrl } from 'src/store/login/loginActions';
import { DomainUrlRedirect } from 'src/components/domainUrlRedirect';
import { useAuthHandler } from 'src/hooks/useAuthHandler';
import { LoginMode } from 'src/types/Login';
import { AskResetEmail } from 'src/components/askResetEmail';
import { ProgressBar } from 'src/components/progressBar';

import { LoginPage } from './LoginPage';
import { CreatePage } from './CreatePage';

export const Authentication = () => {
  // Custom Auth Handler class used for IAP
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { AuthHandler, progressType, signInFunctions, error, mode, updateError } = useAuthHandler();

  const [originalUrl, setOriginalUrl] = useState<string>(() => {
    return '';
  });

  // First time after mounting
  const ciapInstance: any = useMemo(
    () => new ciap.Authentication(new AuthHandler()),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    ciapInstance.start().catch((e: any) => {
      updateError(e);
    });

    // Check if the instance of the CIAP is currently available
    const isCiapInstanceWithOriginalURLAvailable = () => {
      return ciapInstance && ciapInstance.getOriginalURL;
    };

    // If user somehow goes to the reset-password page without first going through the main login page.
    const isLandingOnResetPassword = () => {
      return window.location.href.includes('/reset-password');
    };

    // We should default to the Author one for now as no background or image locations will be set, otherwise.
    if (!isCiapInstanceWithOriginalURLAvailable() || isLandingOnResetPassword()) {
      // VTO uses seddi.me domain, so we can look for that
      if (originalUrl?.includes('seddi.me')) {
        dispatch(setApp('fitroom'));
      } else if (originalUrl?.includes('decorator')) {
        dispatch(setApp('decorator'));
      } else {
        dispatch(setApp('author'));
      }
    }

    try {
      ciapInstance
        .getOriginalURL()
        .then((originalURL: string | null) => {
          setOriginalUrl(originalURL ? originalURL : '');

          // TODO(omer): replace with switch case
          // TODO(omer): add textura here somewhere
          // check original url to show a different background based on which app is being redirected
          if (originalURL?.includes('fitroom') || originalURL?.includes('seddi.me')) {
            dispatch(setApp('fitroom'));
          } else if (originalURL?.includes('author')) {
            dispatch(setApp('author'));
          } else if (originalURL?.includes('textura')) {
            dispatch(setApp('textura'));
          } else if (originalURL?.includes('decorator')) {
            dispatch(setApp('decorator'));
          } else {
            // Default to Author
            dispatch(setApp('author'));
          }

          if (originalURL?.includes('onboarding') && !originalURL?.includes('navigate=login')) {
            navigate('/create-account');
          }
        })
        .catch((e: any) => {
          console.log('error:', e);
        });
    } catch (e) {
      updateError({ message: 'Invalid API key' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ciapInstance, dispatch, navigate, originalUrl]);

  useEffect(() => {
    dispatch(setLoginPageUrl(window.location.href));
  }, [dispatch]);

  // Invalid API key: missing apiKey - set manually if there is no apiKey in the URL
  // Invalid project: API_KEY or AUTH_DOMAIN not correct/provided in the config
  // restart-process code: url query params have expired and user needs to re-authenticate
  // invalid-argument: apiKey is present, but is incorrect
  const isErrorPresent = () => {
    return (
      error.message === 'Invalid API key' ||
      error.message === 'Invalid project!' ||
      error.code === 'restart-process' ||
      error.code === 'invalid-argument' ||
      error.code === 'auth/invalid-login-credentials' ||
      error.code === 'auth/invalid-email' ||
      error.code === 'auth/invalid-password' ||
      error.code === 'auth/email-not-found'
    );
  };

  // No errors, and URL must not be /reset-password
  if (isErrorPresent() && !window.location.href.includes('/reset-password')) {
    return <DomainUrlRedirect />;
  }

  if (mode === LoginMode.ProgressBar) {
    return <ProgressBar progressType={progressType} />;
  }

  const props = {
    signInFunctions,
    updateError,
    error,
    mode,
    originalUrl,
  };

  return (
    <Routes>
      <Route path='/' element={<LoginPage {...props} />} />
      <Route path='login' element={<LoginPage {...props} />} />
      <Route path='reset-password' element={<AskResetEmail originalUrl={props.originalUrl} />} />
      <Route path='create-account' element={<CreatePage {...props} />} />
    </Routes>
  );
};
