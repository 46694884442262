import styled from 'styled-components';

export const FieldContainer = styled.div``;

export const FieldLabel = styled.label`
  ${({ theme: { font } }) => font.t3};
  display: block;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;
