import mixpanel from 'mixpanel-browser';

import { REACT_APP_AUTHOR_MIXPANEL, ENV } from '../constants';

type PeopleFields = {
  [key: string]: any;
};

export class MixpanelManager {
  public static init() {
    if (['prod-app', 'staging'].includes(ENV) && REACT_APP_AUTHOR_MIXPANEL) {
      // eslint-disable-next-line camelcase
      mixpanel.init(REACT_APP_AUTHOR_MIXPANEL, {
        api_host: 'https://api.mixpanel.com',
      });
    }
  }
  public static registerMixpanel(user: {
    id: string;
  }) {
    if (REACT_APP_AUTHOR_MIXPANEL && mixpanel.get_distinct_id()) {
      mixpanel.identify(user.id);
    }
  }
  public static setPeople(fields: PeopleFields) {
    if (REACT_APP_AUTHOR_MIXPANEL && mixpanel.get_distinct_id()) {
      mixpanel.people.set(fields);
    }
  }
  public static setPeopleOnce(fields: PeopleFields) {
    if (REACT_APP_AUTHOR_MIXPANEL && mixpanel.get_distinct_id()) {
      mixpanel.people.set_once(fields);
    }
  }
  public static pushTrackEvent(eventName: string, payload?: any) {
    if (REACT_APP_AUTHOR_MIXPANEL && mixpanel.get_distinct_id()) {
      mixpanel.track(eventName, payload);
    }
  }
}
