import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailLink } from 'firebase/auth';
import { API_KEY, AUTH_DOMAIN, TENANT_ID } from 'src/constants/constants';

interface ISignIn {
  email: string;
  continueUrl: string;
}

const config = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
};

const LOGOUT_PARAMS = 'navigate=login&gcp-iap-mode=CLEAR_LOGIN_COOKIE';

const SignIn = (props: ISignIn) => {
  const { email, continueUrl } = props;
  const updateAndContinue = () => {
    if (continueUrl && continueUrl !== '') {
      window.location.assign(
        `${continueUrl}${continueUrl.includes('?') ? '&' : '?'}${LOGOUT_PARAMS}`,
      );
    }
  };

  useEffect(() => {
    const signin = (_email: string) => {
      try {
        const firebaseApp = initializeApp(config);
        const auth = getAuth(firebaseApp);
        if (TENANT_ID) {
          auth.tenantId = TENANT_ID;
        }

      signInWithEmailLink(auth, _email, window.location.href)
        .then((result) => {
          updateAndContinue();
        })
        .catch((error) => {
          console.log(error.message);
          updateAndContinue();
        });
      } catch (e) {
        console.log('error: ', e, typeof e);
        updateAndContinue();
      }
    }
    signin(email);

    // We do not care about dependencies because this is only called on mount, but there is an issue with linter so we comment it out
    // see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook for more info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default SignIn;
