import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';

import { Link } from '@seddi/ui-kit';

import * as S from './ResetLink.styles';

export const ResetLink = () => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/reset-password');
  };
  // TODO: setState on an empty object will render this component. We need to do this so that the reset link is not shown if not on the reset-password url.
  // Once we have firebase 9 set up, we can convert this class into a functional component, and use `useLocation` instead of this work around.
  return (
    <S.LinkWrapper>
      <Link
        data-dd-action-name='forgotYourPassword'
        text={I18n.t('reset.forgotPasswordLink')}
        onClick={onClick}
      />
    </S.LinkWrapper>
  );
};
