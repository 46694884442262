import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { applyActionCode, getAuth } from 'firebase/auth';
import { datadogRum } from '@datadog/browser-rum';

import { MixpanelManager } from 'src/tracking/mixpanelManager';
import { API_KEY, AUTH_DOMAIN, TENANT_ID } from 'src/constants/constants';
import { SeddiLoginState } from 'src/store/store';

interface IVerifyEmail {
  code: string;
  continueUrl: string;
}

const config = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
};

const LOGOUT_PARAMS = 'navigate=login&gcp-iap-mode=CLEAR_LOGIN_COOKIE';

const Verification = (props: IVerifyEmail) => {
  const { code, continueUrl } = props;
  const { name } = useSelector((state: SeddiLoginState) => state.app);
  const updateAndContinue = () => {
    if (continueUrl && continueUrl !== '') {
      window.location.assign(
        `${continueUrl}${continueUrl.includes('?') ? '&' : '?'}${LOGOUT_PARAMS}`,
      );
    }
  };

  useEffect(() => {
    // This automatically confirms email if the code is valid and present
    const verifyCode = (_code: string) => {
      try {
        const firebaseApp = initializeApp(config);
        const auth = getAuth(firebaseApp);
        if (TENANT_ID) {
          auth.tenantId = TENANT_ID;
        }

        applyActionCode(auth, code)
          .then(() => {
            // successfully applied action code, use continue url
            datadogRum.addAction('applyActionCodeSuccess');
            updateAndContinue();
          })
          .catch((error) => {
            console.log(error.message);
            datadogRum.addAction('firebaseActionCodeError', {
              error: error.message,
            });
            updateAndContinue();
          });
      } catch (e) {
        console.log('error: ', e, typeof e);
        datadogRum.addAction('firebaseAuthError', {
          error: e,
        });
        updateAndContinue();
      } finally {
        if (name === 'author') {
          MixpanelManager.pushTrackEvent('Email validated');
        }
      }
    };

    verifyCode(code);

    // We do not care about dependencies because this is only called on mount, but there is an issue with linter so we comment it out
    // see https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook for more info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return null;
};

export default Verification;
