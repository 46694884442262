import React from 'react';

import { Button } from '@seddi/ui-kit';

import * as S from './VerifyEmail.styles';

export interface VerifyEmailProps {
  originalUrl: string;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ originalUrl }: VerifyEmailProps) => {
  const continueProcess = async () => {
    try {
      if (!originalUrl) {
        window.location.reload();
      } else {
        window.location.assign(`${originalUrl}${originalUrl.includes('?') ? '&' : '?'}navigate=login`);
        
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <S.TextAndButtonsContainer>
      <S.ButtonsContainer>
        <Button text='Continue' width='full' onClick={continueProcess} />
      </S.ButtonsContainer>
    </S.TextAndButtonsContainer>
  );
};

export { VerifyEmail };
