import * as constants from "./loginConstants"

const loginReducer = (state = {
  loginPageUrl: "",
  resetEmail: "",
  resetMessage: "",
},
  action: constants.LoginAction) => {

  let newState = state
  switch (action.type) {
    case constants.SET_LOGIN_PAGE_URL_VALUE:
      newState = {
        ...newState,
        loginPageUrl: action.loginPageUrl,
      }
      break
    default:
      newState = { ...newState }
      break
  }
  return newState
}

export default loginReducer
