const english = {
  login: {
    googlePrompt: 'Sign in with Google',
    emailPrompt: 'Email',
    passwordPrompt: 'Password',
    alternative: 'OR',
    signIn: 'Sign In',
    logIn: 'Log In',
    signingIn: 'Signing In...',
    signingOut: 'Signing Out...',
    loading: 'Loading...',
    loginLink: 'Already have an account? Log in',
    signInDisclaimer: '(Please enable cookies to continue)',
    signOutSuccessful: 'User has been signed out successfully.',
    issuesSigningIn: 'If you have any issues signing in, please contact:',
    validEmail: 'Invalid email',
  },
  reset: {
    emailPrompt: 'Email',
    emailLabel: 'Enter email:',
    resetPrompt: 'Send Instructions',
    resendPrompt: 'Re-send Instructions',
    returnToLogin: 'Return to Login',
    resetError: 'An error occurred. Please try again.',
    resetSuccessful: 'An email will be sent to the account shortly.',
    forgotPasswordLink: 'Forgot your password?',
    thankYou: 'Thank You!',
    needNewPassword: 'Need a new password?',
    easy: "Don't worry, it's easy.",
    hereToHelp: 'We’re here to help.',
    instructionsEmail: 'An email has been sent to you with instructions to reset your password.',
    tellUsEmail: 'Enter your email to receive the instructions.',
    cancel: 'Cancel',
  },
  createAccount: {
    back: 'Back',
    createAccountLink: 'Don’t have an account yet? Sign up now',
    displayNameLabel: 'Display Name:',
    displayNamePrompt: 'Display Name',
    emailPrompt: 'example@company.com',
    emailLabel: 'Enter email:',
    validEmail: 'Invalid email',
    googlePrompt: 'Create account with Google',
    createPrompt: 'Create Account',
    resendPrompt: 'Re-send Instructions',
    accountCreated: 'Account created!',
    welcome: 'Welcome to Fitroom',
    getStarted: 'Get started with SEDDI',
    subHeading:
      'Create an account to start digitizing and designing with SEDDI Textura and SEDDI Author',
    fitroomSubHeading: 'Create an account to start digitizing and designing with SEDDI Fitroom',
    instructionsEmail: 'An email has been sent to you for confirmation.',
    enterPasswordPrompt: 'Enter Password: ',
    enterPasswordInfo:
      'Passwords must contain at least 12 characters. The longer and more complex a password is, the more  secure it will be.',
    reEnterPasswordPrompt: 'Re-Enter Password: ',
    errorPasswordMatch: 'Passwords do not match',
    errorPasswordStrength: 'Password is not strong',
    genericError: 'An error ocurred, please refresh the page and try again.',
    invalidCodeHeader: 'Oops :(',
    invalidCodeMessage: 'The email verification link you are using is invalid or has expired',
    agreeTos: 'I agree to the SEDDI Terms ',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    and: ' and ',
  },
  verify: {
    continue: 'Continue',
  },
  auth: {
    confirmEmail: 'Confirm Email',
    emailConfirmed: 'Email Confirmed',
    pleaseWait: 'Please wait while your email address is confirmed',
    changePasswordPrompt: 'Change your password',
    confirmingEmail: 'Confirming your email',
    invalidCodeHeader: 'Something went wrong',
    invalidCodeMessage: 'The password reset link you are using is invalid or has expired',
    userNotFoundHeader: 'Something went wrong,',
    userNotFoundMessage:
      "We can't find this user in our system. Please check with your system administrator",
    enterPasswordPrompt: 'Enter Password: ',
    reEnterPasswordPrompt: 'Confirm Password: ',
    changePassword: 'Change Password',
    passwordChanged: 'New password created successfully!',
    login: 'Log In',
    loginAuthor: 'Log Into Author',
    loginFitroom: 'Log Into Fitroom',
    loginTextura: 'Log Into Textura',
    inputFieldMessages: {
      createStrongPassword: '🤓 Let’s create a strong password!',
      weakPasswordLength: '😔 Weak. Must contain at least 8 characters.',
      weakNoNumber: '😔 Weak. Must contain at least one number.',
      weakSpecialCharacter: '😔 Weak. Must contain at least one special character.',
      weakNoCharacter: '😔 Weak. Must contain at least one characters.',
      strongPassword: '😎 OMG! This password rocks!',
    },
  },
  error: {
    wrong: 'Something went wrong',
    backLogin: 'Back to Log In',
    backSignup: 'Back to Sign Up',
    backLogout: 'Log In Again',
    login: 'Login',
    loggedOut: 'You have been logged out',
    logInError: 'Please try again in a moment',
    singUpError: 'Please try again in a moment',
    accountExist: 'An account already exists with the same email',
    auth: 'Invalid email/password combination.',
  },
  validate: {
    title: 'Welcome!',
    sent: 'An email has been sent to ',
    verify:
      ' Verify your email address by clicking the link in the email to create your SEDDI account.',
    cantFind: "Can't find it? Check the spam folder.",
  },
};

export default english;
