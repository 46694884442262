import React from 'react';
import { I18n } from 'react-redux-i18n';

import * as S from './GoogleButton.styles';
import { SignInParameters } from 'src/types/Login';

interface IGoogleButtonProps {
  signInFunctions: SignInParameters;
  setErrorMessage?: Function;
}
export const GoogleButton = ({ signInFunctions, setErrorMessage }: IGoogleButtonProps) => {
  return (
    <S.GoogleButtonContainer
      type='button'
      data-dd-action-name='SignInWithGoogle'
      onClick={
        signInFunctions
          ? () => {
              signInFunctions.onSignInWithGoogle();
            }
          : () => {
              setErrorMessage && setErrorMessage({ message: 'sign-in function missing' });
            }
      }
    >
      <S.GoogleButtonImage src={window.location.origin + '/img/google.svg'} alt='google_logo' />
      <S.GoogleButtonText>{I18n.t('login.googlePrompt')}</S.GoogleButtonText>
    </S.GoogleButtonContainer>
  );
};
