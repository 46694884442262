import styled from 'styled-components';

export const TextAndButtonsContainer = styled.div`
  display: flex;
  width: 420px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
