import { useState } from 'react';
import * as ciap from 'gcip-iap';
import {
  getAuth as getAuthorization,
  signInWithRedirect,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
} from 'firebase/auth';
import { getApp, initializeApp } from 'firebase/app';
import { UserCredential, FirebaseAuth, User } from '@firebase/auth-types';

import { API_KEY, AUTH_DOMAIN, TENANT_ID } from 'src/constants';
import { ErrorParameters, LoginMode, ProgressType, SignInParameters } from 'src/types/Login';

export const useAuthHandler = () => {
  const [mode, setMode] = useState(() => {
    return LoginMode.None;
  });
  const [signInFunctions, setSignInFunctions] = useState(() => {
    return {} as SignInParameters;
  });
  const [progressType, setProgressType] = useState(() => {
    return ProgressType.None;
  });

  const [error, setError] = useState(() => {
    return {
      message: '',
      code: '',
      retry: undefined,
    } as ErrorParameters;
  });

  // REVIEW: coupled react state with external class
  class AuthHandler implements ciap.AuthenticationHandler {
    config: any;
    // Called after selectTenant().
    // initializes the app auth within firebase
    getAuth = (apiKey: string, tenantId: string | null): FirebaseAuth => {
      let auth = null;
      if (API_KEY === '' || apiKey !== API_KEY || AUTH_DOMAIN === '') {
        throw new Error('Invalid project!');
      }
      try {
        this.config = {
          apiKey: API_KEY,
          authDomain: AUTH_DOMAIN,
        };
        auth = getAuthorization(getApp(tenantId || undefined));
        // Tenant ID should be already set on initialization below.
      } catch (e) {
        const firebaseApp = initializeApp(this.config, tenantId || '[DEFAULT]');
        auth = getAuthorization(firebaseApp);
        if (tenantId) {
          auth.tenantId = tenantId;
        }
      }
      return auth as any;
    };

    // This will not be called until staging and demo are tenants in the IAP.
    // It will need to be updated at that time.
    // Will need tenant ids passed in as variables too.
    selectTenant = (
      projectConfig: { projectId: string },
      tenantIds: string[],
    ): Promise<ciap.SelectedTenantInfo> => {
      return new Promise((resolve, reject) => {
        let selectedTenantId = tenantIds.find((tenId: string) => {
          return tenId === TENANT_ID;
        });
        if (!selectedTenantId) {
          selectedTenantId = '';
        }
        resolve({
          tenantId: selectedTenantId,
          providerIds: [],
        });
      });
    };

    signIn = (
      onSignInWithGoogle: () => boolean,
      onSignInWithEmailAndPassword: (email: string, password: string) => Promise<boolean>,
    ) => {
      setMode(LoginMode.SignIn);
      setSignInFunctions({
        onSignInWithGoogle,
        onSignInWithEmailAndPassword,
      } as SignInParameters);
    };

    // Called after Auth is set up.
    // Creates two functions to be used for either signing into google or via auth/password
    // Potentially could use getAuth(getApp(match.tenantId || undefined)) as CIAP and firebase no longer line up correctly
    startSignIn = (auth: FirebaseAuth): Promise<UserCredential> => {
      return new Promise((resolve, reject) => {
        this.signIn(
          () => {
            updateError(null);
            setProgressType(ProgressType.SignIn);
            signInWithRedirect(auth as any, new GoogleAuthProvider()).catch((error: any) => {
              updateError(error);
            });
            return false;
          },
          async (email: string, password: string) => {
            updateError(null);
            setProgressType(ProgressType.SignIn);
            try {
              const userCredential: any = await signInWithEmailAndPassword(auth as any, email, password);
              resolve(userCredential);
            } catch (e: any) {
              updateError(e);
            }
            return false;
          },
        );
      });
    };

    // Implemented only for debugging purposes
    processUser = (user: User): Promise<User> => {
      return user
        .getIdToken(true)
        .then(() => {
          return user;
        })
        .catch((error: any) => {
          updateError(error);
          return user;
        });
    };

    // Handles signing out of the IAP - Currently does nothing, but needs to remain in component due to interface
    completeSignOut = (): Promise<void> => {
      setProgressType(ProgressType.SignOut);
      return Promise.resolve();
    };

    // Hides the progress bar/spinner
    hideProgressBar = () => { };

    // Shows the progress bar/spinner
    showProgressBar = () => {
      setMode(LoginMode.ProgressBar);
    };

    handleError = (error: Error | ciap.CIAPError) => {
      updateError(error);
    };
  }

  // Updates and displays an error message
  const updateError = (
    error: {
      code?: string;
      message?: string;
      retry?: any;
      errorInfo?: { code?: string; message?: string };
      underlyingReason?: string;
      errors?: any;
    } | null,
  ) => {
    if (error && error !== null) {
      setProgressType(ProgressType.None);
      if (error.errorInfo) {
        setError({ message: error.errorInfo.message, code: error.errorInfo.code });
        return;
      } else if (error.errors && error.errors.length > 0) {
        const firstError = error.errors[0].message;
        setError({ message: firstError.message });
        return;
      }
      setError({ ...error });
    }
  };

  return { AuthHandler, progressType, signInFunctions, error, mode, updateError };
};
