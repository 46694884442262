import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;  
  line-height: 20px;
  letter-spacing: 0em;
  heigth: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  border-radius: 20px;
  border-width: 2px; 
  border-style: solid;
  pointer-events: auto;
  opacity: 1;
  box-shadow: rgba(11, 11, 12, 0.2) 1px 1px 1px;
  transition: all 75ms ease 0s;
  border-color: rgba(0, 0, 0, 0);
  background-color: rgb(247, 247, 247); 
  color: rgb(78, 15, 210);
  fill: rgb(78, 15, 210);
  text-decoration: none;
`;
