import styled from 'styled-components';

export const ResetForm = styled.form<{ $isSuccess: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: ${({ $isSuccess }) => ($isSuccess ? 'center' : 'left')};
`;

export const Accordion = styled.form<{ show: boolean }>`
  display: grid;
  grid-template-rows: ${({ show }) => (show ? 1 : 0)}fr;
  transition: grid-template-rows 2s ease;
`;

export const AccordionContent = styled.div`
  overflow: hidden;
`;

export const ResetSubmitContainer = styled.div<{ $isSuccess: boolean }>`
  margin-top: auto;
  margin-bottom: 1rem;
  margin-top: ${({ $isSuccess }) => ($isSuccess ? '1.25rem' : '')};
  justify-content: ${({ $isSuccess }) => ($isSuccess ? 'center' : 'left')};
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

export const ResetFieldContainer = styled.div`
  margin-bottom: 1rem;
  > input {
    width: 100%;
  }
`;

export const ResetFieldLabel = styled.label`
  ${({ theme: { font } }) => font.t3};
  display: block;
  margin-bottom: 0.5rem;
`;

export const ResetFormSubHeading = styled.div`
  ${({ theme: { font } }) => font.t2};
  margin-bottom: 0.5rem;
`;

export const ResetFormHeading = styled.div`
  ${({ theme: { font } }) => font.t1};
  margin-bottom: 1rem;
`;

export const ResetMessage = styled.div`
  ${({ theme: { font } }) => font.t3};
  color: ${({ theme: { color } }) => color.ac11};
  margin: 1.25rem 0 0.5rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;
